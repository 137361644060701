import React, { useState, useEffect, useContext, useRef } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTable, faUserPlus, faTrashAlt, faSearch, faPlus, faArrowDown, faArrowUp, faCheck, faSyncAlt, faCopy, faEllipsisV, faChartLine, faInfoCircle, faEdit, faSnowflake, faFile } from '@fortawesome/free-solid-svg-icons';
import { faLock, faFilePdf, faFileCsv, faFilePowerpoint, faFileExcel, faFileImage, faFileArchive } from '@fortawesome/free-solid-svg-icons';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Papa from 'papaparse';
import './fileList.css'
import { UserContext, useUser } from '../../context/userContext';
import axios from 'axios';
import Pagination from './pagination';
import * as XLSX from 'xlsx';
import { useOutletContext, useParams } from 'react-router-dom';
import { useRefresh } from '../../refreshContext /refreshContext';
import InviteOverlay from './shareitemComponent';

import { debounce } from 'lodash';


function FileRepository() {
    const { refreshFileList, refreshTrigger } = useRefresh()
    const { lockerId, activeTab } = useOutletContext() || {};
    const { lokerId } = useParams()
    const { user } = useContext(UserContext);
    const { triggerFileSelection } = useContext(UserContext);
    const { formatFileSize, formatDate } = useUser();
    const [fileList, setFileList] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showSendOptions, setShowSendOptions] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isOverlayLoading, setIsOverlayLoading] = useState(false);
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [searchTimeout, setSearchTimeout] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [tableName, setTableName] = useState('');
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    const [fileContent, setFileContent] = useState({ url: '', type: '' });
    const [showMappingCheckbox, setShowMappingCheckbox] = useState(false);
    const [mappingChecked, setMappingChecked] = useState(true);
    const [showMappingInterface, setShowMappingInterface] = useState(false);
    const [columnMappings, setColumnMappings] = useState([]); // To store mappings
    const [csvData, setCsvData] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('')
    const [autoMappingEnabled, setAutoMappingEnabled] = useState(false);
    const [overlayInfoOpen, setOverlayInfoOpen] = useState(false);
    const [sortDirection, setSortDirection] = useState(null);
    const [originalFileList, setOriginalFileList] = useState([]);
    const [sortOrder, setSortOrder] = useState('original');
    const [displayedFiles, setDisplayedFiles] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const currentDateTime = new Date().toISOString();
    const [newTag, setNewTag] = useState('');

    const [fileDetails, setFileDetails] = useState({});
    const [currentFile, setCurrentFile] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [sortName, setSortName] = useState(null);
    const [sortLastUpdated, setSortLastUpdated] = useState(null);
    const [editableFileName, setEditableFileName] = useState('');
    const [sortType, setSortType] = useState('');
    const [sortSize, setSortSize] = useState('');
    const [inviteUrl, setInviteUrl] = useState('');
    const [showInviteOverlay, setShowInviteOverlay] = useState(false)
    const [copyIcon, setCopyIcon] = useState(false);
    const dropdownRef = useRef(null);
    const userId = user?.userId;
    const sortIcon = sortDirection === 'asc' ? faArrowUp : faArrowDown;
    const owner = user?.name.split('@')[0];
    const { viewFile } = useUser();
    const [lockerDetails, setLockerDetails] = useState(null);
    // const { refreshFileList } = useRefresh();
    const [showDropdown, setShowDropdown] = useState(null);
    const [selectedLockerToken, setSelectedLockerToken] = useState()
    const [password, setPassword] = useState(null);
    const [selectedFileExtension, setSelectedFileExtension] = useState('');
    const [targetDb, setTargetDb] = useState('rds');
    const [selectedLocker, setSelectedLocker] = useState(
        lockerDetails?.find((locker) =>
            locker.token === (currentFile?.locker_id)
        )?.name || ''
    );
    console.log(owner)
    console.log(selectedLocker)

    useEffect(() => {
        const selectedLockerObj = lockerDetails?.find(
            (locker) => locker.name === selectedLocker
        );
        if (selectedLockerObj) {
            setSelectedLockerToken(selectedLockerObj.token);

        }
    }, [selectedLocker, lockerDetails, setSelectedLockerToken]);


    console.log(lockerId);

    const columnNames = [
        'Ignore this field',
        'First Name',
        'Last Name',
        'Title',
        'Email',
        'Second Email',
        'Third Email',
        'Phone',
        'Mobile',
        'Company Name',
        'Company URL',
        'LinkedIn',
        'City Name',
        'State Name',
        'Country Name',
        'Notes',
        'Company Employee Size',
        'Company Street 1',
        'Company State',
        'Company City',
        'Company ZIP',
        'Company Country',
        'Company Phone',
        'Company Linkedin',
        'twitter',
        'Lead Status',
        'Custom Fields',
    ];



    useEffect(() => {
        if (currentFile && currentFile.name) {
            setEditableFileName(currentFile.name);
        }
    }, [currentFile]);


    useEffect(() => {
        let timer;
        // If there is a success message, set a timer to clear it after 3 seconds
        if (successMessage) {
            timer = setTimeout(() => {
                setSuccessMessage('');
            }, 3000);
        }

        // Clear the timer if the component unmounts or the success message changes
        return () => clearTimeout(timer);
    }, [successMessage]); // This effect depends on successMessage

    // useEffect(() => {
    //     if (autoMappingEnabled) {
    //         // Perform auto-mapping based on matching field names
    //         const updatedMappings = columnMappings.map(mapping => {
    //             const matchingField = columnNames.find(name => name === mapping.sourceField);
    //             if (matchingField) {
    //                 return { ...mapping, onepgrField: matchingField };
    //             }
    //             return mapping;
    //         });
    //         setColumnMappings(updatedMappings);
    //     }
    // }, [autoMappingEnabled, columnMappings, columnNames]);

    useEffect(() => {
        if (autoMappingEnabled) {
            const autoMapped = columnMappings.map(mapping => {
                if (!mapping.manuallySelected) {
                    const matchingIndex = columnNames.findIndex(name =>
                        name.toLowerCase().replace(/[\s,_]/g, '') === mapping.sourceField.toLowerCase().replace(/[\s,_]/g, '')
                    );
                    return { ...mapping, onepgrField: matchingIndex !== -1 ? columnNames[matchingIndex] : '' };
                }
                return mapping;
            });
            setColumnMappings(autoMapped);
        }
    }, [autoMappingEnabled, columnMappings, columnNames]);


    useEffect(() => {
        if (!autoMappingEnabled) {
            // When auto-mapping is disabled, clear the mappings.
            const clearedMappings = columnMappings.map(mapping => ({
                ...mapping,
                onepgrField: '',
                manuallySelected: false, // Clearing this flag as well, assuming we're resetting any manual selection
            }));
            setColumnMappings(clearedMappings);
        } else {

        }
    }, [autoMappingEnabled]);


    useEffect(() => {
        console.log("Overlay State: ", overlayInfoOpen, "Current File: ", currentFile);
    }, [overlayInfoOpen, currentFile]);

    const handleAutoMappingToggle = () => {
        setAutoMappingEnabled(!autoMappingEnabled);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowSendOptions(false); // Assuming this is your state setter for showing/hiding the dropdown
            }
        }

        // Only add the event listener if the dropdown is shown
        if (showSendOptions) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        // Cleanup function to remove the event listener
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showSendOptions]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (showDropdown && !event.target.closest('.actions-dropdown')) {
                setShowDropdown(null);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showDropdown]);



    // const handleIconClick = () => {
    //     if (selectedFiles.length === 0) {
    //         // If no files are selected, show an alert prompt
    //         window.alert("Please select a file to proceed");
    //     } else {
    //         // If files are selected, toggle the visibility of the send options
    //         setShowSendOptions(!showSendOptions);
    //     }
    // };

    useEffect(() => {
        fetchLockerDetails();
    }, []);


    const fetchLockerDetails = async () => {
        try {
            const response = await axios.get('https://pages.onepgr.com/session/get-data-bank-items', {
                params: { user_id: user?.userId },
            });
            if (response.data.data.length > 0) {
                setLockerDetails(response.data.data); // Store all locker details


            } else {
                console.log('No locker details found');
                setLockerDetails(null);


            }
        } catch (error) {
            console.error('Error fetching locker details:', error);
            setLockerDetails(null);
        }
    };


    const toggleDropdown = (event, fileId) => {
        event.stopPropagation();
        setShowDropdown(prevId => prevId === fileId ? null : fileId);;
    };


    const handleIconClick = () => {
        if (selectedFiles.length === 1) {
            const selectedFile = displayedFiles.find(file => file._id === selectedFiles[0]);
            const fileExtension = selectedFile.source_url.split('.').pop().toLowerCase();
            if (fileExtension === 'csv' || fileExtension === 'xls' || fileExtension === 'xlsx') {
                setShowSendOptions(!showSendOptions);
                setSelectedFileExtension(fileExtension); // Store the file extension in state
            } else {
                window.alert("Please select a CSV or XLS file to proceed");
            }
        } else if (selectedFiles.length > 1) {
            setShowSendOptions(!showSendOptions);
        } else {
            window.alert("Please select at least one file to proceed");
        }
    };


    const handleSendToRDSClick = (file, targetDb = 'rds') => {
        console.log("File object:", file); // Log the file object to see its content
        console.log("File ID and Source URL Present:", file && file._id && file.source_url);
        console.log("Target DB:", targetDb); // Add this log
        if (file && file._id && file.source_url) {
            console.log("File has _id and source_url, proceeding with processing:", file._id);
            // Extract necessary information from the file object
            const { _id, source_url } = file;

            // Extract the base file name without extension from the URL
            const fileName = source_url.substring(source_url.lastIndexOf('/') + 1, source_url.lastIndexOf('.'));


            // Generate a timestamp in a URL/file-friendly format
            const timestamp = new Date().toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false
            }).replace(/\/|,|:/g, "-").replace(/\s/g, "_");

            // Combine the file name and timestamp to prepopulate the table name
            const prepopulatedTableName = `${fileName}_${timestamp}`;

            // Update the tableName state with the prepopulated value
            setTableName(prepopulatedTableName);

            // Fetch and parse CSV content from the file URL
            fetchAndParseDataFile(source_url, setColumnMappings);

            // Show the mapping interface or the next step
            setShowMappingCheckbox(true);
            setTargetDb(targetDb);
        } else {
            console.log("Selected File:", file); // Add for debugging
            console.log("File ID and Source URL Present:", file && file._id && file.source_url); // Add for debugging
            alert("No file selected or file missing required properties (_id, source_url)!");
        }
    };

    const handleSendMultipleFilesToRDS = (selectedFiles) => {
        if (selectedFiles.length > 0) {
            const fileUrls = selectedFiles.map(file => file.source_url);
            const fileName = selectedFiles[0].source_url.substring(selectedFiles[0].source_url.lastIndexOf('/') + 1, selectedFiles[0].source_url.lastIndexOf('.'));
            const timestamp = new Date().toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false
            }).replace(/\//g, "-").replace(/,/g, "-").replace(/:/g, "-").replace(/\s/g, "_");
            const tableName = `${fileName}_${timestamp}`;

            sendMultipleFilesToRDS(fileUrls, tableName);
        } else {
            alert("Please select at least one file to proceed");
        }
    };

    const sendMultipleFilesToRDS = async (fileUrls, tableName) => {
        try {
            const response = await fetch('https://crawl.onepgr.com:3002/send-multiple-to-rds', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ fileUrls, tableName }),
            });

            if (response.ok) {
                const result = await response.json();
                console.log(result);
                // Handle the response as needed, e.g., show success message
            } else {
                throw new Error('Failed to send files to RDS');
            }
        } catch (error) {
            console.error('Error:', error);
            // Handle the error, e.g., show error message
        }
    };

    const fetchAndParseDataFile = async (fileUrl) => {
        const extension = fileUrl.split('.').pop();

        try {
            const response = await fetch(fileUrl);
            const blob = await response.blob();

            if (extension === 'csv') {
                // Handling CSV files
                const reader = new FileReader();
                reader.onload = function (event) {
                    const csvText = event.target.result;
                    Papa.parse(csvText, {
                        complete: (results) => {
                            processData(results.data);
                        },
                        header: false,
                    });
                };
                reader.readAsText(blob);
            } else if (extension.match(/xls|xlsx/)) {
                // Handling Excel files
                const reader = new FileReader();
                reader.onload = function (event) {
                    const data = new Uint8Array(event.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                    processData(json);
                };
                reader.readAsArrayBuffer(blob);
            }
        } catch (error) {
            console.error("Error fetching or parsing data file:", error);
        }
    };

    function processData(data) {
        if (data.length > 1) {
            const headers = data[0]; // The first row for headers
            const firstRow = data[1]; // The second row for example data

            const mappings = headers.map((header, index) => ({
                sourceField: header,
                onePgrField: '',
                exampleData: firstRow[index] || ''
            }));

            // Assuming setColumnMappings and setCsvData are setState hooks or similar functions
            setColumnMappings(mappings);
            setCsvData(data); // Store the entire data
        } else {
            console.error('Data file does not contain enough data.');
        }
    }






    const toggleSearch = () => {
        setIsSearchVisible(!isSearchVisible);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };




    const clearSearch = () => {
        setSearchTerm('');
        refreshFileList();

    };


    const displayMessage = (message, type) => {
        const messageElement = document.createElement("div");
        messageElement.textContent = message;
        messageElement.className = `notification ${type}`; // Use className for overriding

        const container = document.getElementById('notification-container') || document.body;
        container.appendChild(messageElement);

        // Start slide out effect before removing the message
        setTimeout(() => {
            messageElement.style.animation = 'slideOut 0.5s ease forwards';

            setTimeout(() => {
                if (container.contains(messageElement)) {
                    container.removeChild(messageElement);
                }
            }, 500); // Matches the duration of the slideOut animation
        }, 4500); // Display time before starting to slide out
    };



    const sendSelectedFilesToRDS = async () => {
        console.log(targetDb)
        setIsOverlayLoading(true);
        if (selectedFiles.length === 0) {
            alert('No file selected!');
            setIsOverlayLoading(false);
            return;
        }

        const fileUrls = selectedFiles.map(fileId => {
            const fileData = displayedFiles.find(file => file._id === fileId);
            return fileData ? fileData.source_url : 'Unknown URL'; // Fallback for missing file data
        });

        let payload = {
            fileKeys: fileUrls, // Using file URLs instead of IDs or names
            tableName,
            targetDb,
        };

        // Add payloadData to the payload based on whether it is mapped or raw CSV




        try {
            const response = await fetch('https://crawl.onepgr.com:3002/send-to-rds', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            console.log(result);
            const newTableName = result.tableName
            console.log(newTableName)


            if (result.successMessage) {
                // displayMessage(result.successMessage, 'success');
            }
            if (result.errorMessage) {
                displayMessage(result.errorMessage, 'error');
                setIsOverlayLoading(false);
                return;
            }

            // Assuming you extract or have the necessary details for the next request
            const nextPayload = {
                user_id: userId,
                locker_id: lockerId || '',
                source_url: tableName, 
                name: newTableName, 
                owner_name: owner,
                object_type: 'table',
                date: currentDateTime,
                database: targetDb,
            };

            const nextResponse = await fetch('https://pages.onepgr.com/session/create-data-bank-table', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(nextPayload),
            });

            if (!nextResponse.ok) {
                throw new Error(`HTTP error! status: ${nextResponse.status}`);
            }

            const nextResult = await nextResponse.json();
            console.log(nextResult);

            // Handle the response from the second request, e.g., display a message
            displayMessage('Data bank table created successfully.', 'success');

        } catch (error) {
            console.error('Error:', error);
            displayMessage(`Failed to process: ${error.message}`, 'error');
        } finally {
            setIsOverlayLoading(false);
        }
    };

    // Function to generate a base table name
    const generateTimestamp = () => {
        return new Date().toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        }).replace(/\//g, "-").replace(/,/g, "-").replace(/:/g, "-").replace(/\s/g, "_");
    };
    // Function to extract the file name from the URL
    const extractFileNameFromUrl = (url) => {
        console.log(url);
        const decodedUrl = decodeURIComponent(url);
        const fileName = decodedUrl.substring(decodedUrl.lastIndexOf('/') + 1, decodedUrl.lastIndexOf('.'));
        console.log(fileName)
        // Generate a timestamp in a URL/file-friendly format
        const timestamp = new Date().toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        }).replace(/\//g, "-").replace(/,/g, "-").replace(/:/g, "-").replace(/\s/g, "_");

        // Combine the file name and timestamp
        const formattedFileName = `${fileName}_${timestamp}`;

        return formattedFileName;
    };

    const sendMultipleSelectedFilesToRDS = async (targetDb = 'rds') => {
        setIsOverlayLoading(true);
        if (selectedFiles.length === 0) {
            alert('No files selected!');
            setIsOverlayLoading(false);
            return;
        }

        const fileData = selectedFiles
            .map(fileId => {
                const file = displayedFiles.find(file => file._id === fileId);
                if (file) {
                    const fileExtension = file.source_url.split('.').pop().toLowerCase();
                    if (fileExtension === 'csv' || fileExtension === 'xls') {
                        const formattedFileName = extractFileNameFromUrl(file.source_url);
                        const tableName = formattedFileName;
                        console.log(tableName);
                        return { url: file.source_url, tableName };
                    }
                }
                return null;
            })
            .filter(data => data !== null);

        if (fileData.length === 0) {
            alert('No valid CSV or XLS files selected!');
            setIsOverlayLoading(false);
            return;
        }

        let payload = { fileData: fileData, targetDb: targetDb };
        console.log(fileData);

        try {
            const response = await fetch('https://crawl.onepgr.com:3002/send-multiple-to-rds', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            console.log(result);
            console.log(result.tableNames)
            const newTablename = result.tableNames
            if (result.successMessage) {
                displayMessage(result.successMessage, 'success');
            }

            if (result.errorMessage) {
                displayMessage(result.errorMessage, 'error');
                setIsOverlayLoading(false);
                return;
            }

            // Create a data bank table entry for each file
            for (let i = 0; i < fileData.length; i++) {
                const data = fileData[i];
                const newtableName = result.tableNames[i]; // Get the corresponding table name from result.tableNames

                const nextPayload = {
                    user_id: userId,
                    locker_id: lockerId || '',
                    source_url: data.tableName, // Use the table name from result.tableNames
                    name: newtableName, // Use the table name from result.tableNames
                    owner_name: owner,
                    object_type: 'table'
                };

                console.log(nextPayload)

                const nextResponse = await fetch('https://pages.onepgr.com/session/create-data-bank-table', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(nextPayload),
                });

                if (!nextResponse.ok) {
                    throw new Error(`HTTP error! status: ${nextResponse.status}`);
                }

                const nextResult = await nextResponse.json();
                console.log(nextResult);
            }

            displayMessage('Data bank tables created successfully.', 'success');
        } catch (error) {
            console.error('Error:', error);
            displayMessage(`Failed to process: ${error.message}`, 'error');
        } finally {
            setIsOverlayLoading(false);
        }
    };






    const sendToQuickSight = () => {
        // Implement sending files to QuickSight Dashboard
        console.log("Sending selected files to QuickSight Dashboard...");
    };


    /// main refreshfilelist 

    // const refreshFileList = async () => {
    //     setIsLoading(true);

    //     const baseApiUrl = `https://pages.onepgr.com/session/get-data-bank-source-files`;
    //     const queryParams = new URLSearchParams({
    //         user_id: userId,
    //         locker_id: lockerId,
    //         page_num: currentPage,
    //         nitems: itemsPerPage,
    //     });

    //     // If there's a search term, add it to the query params
    //     if (searchTerm) {
    //         queryParams.append('search', searchTerm);
    //     }

    //     try {
    //         const response = await fetch(`${baseApiUrl}?${queryParams.toString()}`);
    //         const data = await response.json();
    //         console.log(data)
    //         if (response.ok && data.status === 1) {
    //             // Filter out files with object_type = 'link'
    //             const filteredFiles = data.data.filter(file => file.object_type !== 'link');

    //             setDisplayedFiles(filteredFiles); // Set displayed files based on filtered response
    //             setOriginalFileList(filteredFiles);
    //             setTotalItems(filteredFiles.length); // Update total items for pagination
    //             const newFileDetails = data.data.reduce((details, file) => {
    //                 details[file._id] = {
    //                     comments: file.file_info?.comments || '',
    //                     companyInfo: file.file_info?.company_info || '',
    //                     tags: file.file_info?.tags ? file.file_info.tags.split(', ') : [], // Assuming tags are stored as a comma-separated string
    //                 };
    //                 return details;
    //             }, {});

    //             setFileDetails(newFileDetails);
    //         } else {
    //             console.error('Failed to fetch files:', data.message);
    //             setDisplayedFiles([]);
    //             setTotalItems(0);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching files:', error);
    //         setDisplayedFiles([]);
    //         setTotalItems(0);
    //     } finally {
    //         setIsLoading(false);
    //     }
    // };


    const fetchFiles = async () => {
        setIsLoading(true);
        const { files, totalItems } = await refreshFileList(userId, lockerId, currentPage, itemsPerPage, searchTerm);
        setDisplayedFiles(files);
        setOriginalFileList(files);
        setTotalItems(totalItems);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchFiles();
    }, [userId, lockerId, currentPage, itemsPerPage, searchTerm, refreshTrigger]);


    useEffect(() => {
        const setFileDetailsFromResponse = (files) => {
            const newFileDetails = files.reduce((details, file) => {
                details[file._id] = {
                    comments: file.file_info?.comments || '',
                    companyInfo: file.file_info?.company_info || '',
                    tags: file.file_info?.tags ? file.file_info.tags.split(', ') : [],
                };
                return details;
            }, {});
            setFileDetails(newFileDetails);
        };

        setFileDetailsFromResponse(displayedFiles);
    }, [displayedFiles]);



    const deleteFile = async (fileKey, fileId) => {
        setIsOverlayLoading(true);
        try {
            // Prepare requests for both endpoints
            const deleteFromS3Request = fetch('https://crawl.onepgr.com.com:3002/delete-from-s3', {
                method: 'POST', // Using POST as specified
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ fileName: fileKey }), // Assuming fileKey is the fileName for S3
            });

            const deleteDataSourceFileRequest = fetch(`https://pages.onepgr.com/session/delete-data-bank-source-file/${fileId}`, {
                method: 'DELETE', // Using DELETE as required by this endpoint
                headers: {
                    'Content-Type': 'application/json',
                },
                // No body required for this request
            });

            // Execute both requests concurrently
            const responses = await Promise.all([deleteFromS3Request, deleteDataSourceFileRequest]);

            // Check if both responses are OK
            responses.forEach(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
            });

            // Optionally, parse and log the response from the first endpoint
            const result = await responses[0].json();
            console.log(result.message);
            setSuccessMessage(result.message);
            setIsOverlayLoading(false);
            fetchFiles()
            setTimeout(() => setSuccessMessage(''), 3000);
        } catch (error) {
            console.error('Error deleting file:', error);
            setIsOverlayLoading(false);
            // Optionally, display an error message to the user
            setSuccessMessage('Failed to delete the file.'); // Update to display error message
            // Optionally, clear the error message after a delay
            setTimeout(() => setSuccessMessage(''), 3000);
        }
    };


    const selectFile = (fileId) => {
        setSelectedFiles(prevSelectedFiles => {
            if (prevSelectedFiles.includes(fileId)) {
                const newSelectedFiles = prevSelectedFiles.filter(id => id !== fileId);
                setSelectAll(newSelectedFiles.length === displayedFiles.length);
                return newSelectedFiles;
            } else {
                const newSelectedFiles = [...prevSelectedFiles, fileId];
                setSelectAll(newSelectedFiles.length === displayedFiles.length);
                return newSelectedFiles;
            }
        });
    };





    const extractFileNameAndExtension = (fullName) => {
        if (!fullName) return { baseName: 'N/A', extension: 'N/A' }; // Handle null or undefined inputs

        const dotIndex = fullName.lastIndexOf('.');
        if (dotIndex === -1) return { baseName: fullName, extension: 'N/A' }; // Handle files without an extension

        const baseName = fullName.substring(0, dotIndex);
        const extension = fullName.substring(dotIndex + 1).toUpperCase(); // Convert extension to uppercase
        return { baseName, extension };
    };


    const handleMappingChange = (index, selectedValue) => {
        const updatedMappings = columnMappings.map((mapping, i) => {
            if (i === index) {
                // Mark the mapping as manually selected
                return { ...mapping, onepgrField: selectedValue, manuallySelected: true };
            }
            return mapping;
        });
        setColumnMappings(updatedMappings);
    };

    const finalizeMappings = async (selectedFiles, columnMappings, tableName, targetDb) => {
        setShowMappingInterface(false);
        setIsOverlayLoading(true);
        // Ensuring selectedFiles is treated as an array
        if (!Array.isArray(selectedFiles) || selectedFiles.length === 0) {
            alert('No file selected!');
            setShowMappingInterface(false);
            return;
        }

        const fileUrls = selectedFiles.map(fileId => {
            const fileData = displayedFiles.find(file => file._id === fileId);
            return fileData ? fileData.source_url : null;
        }).filter(url => url !== null); // Filter out any null URLs

        let payload = {
            fileKeys: fileUrls,
            tableName,
            columnMappings,
            targetDb
        };

        try {
            const response = await fetch('https://crawl.onepgr.com:3002/map-data', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            console.log(result);
            // Handle the response as needed, e.g., showing success message or progressing to the next step
            if (result.successMessage) {
                displayMessage(result.successMessage, 'success');
            }
            if (result.errorMessage) {
                displayMessage(result.errorMessage, 'error');
                setIsOverlayLoading(false);
                return;
            }

            const nextPayload = {
                user_id: userId, // Replace with actual user ID
                locker_id: lockerId || '',// This might be static or dynamically retrieved
                owner_name: owner,
                source_url: tableName, // Determine how you get this, possibly from `result`
                name: tableName, // This might come from your operation or `result`
            };

            const nextResponse = await fetch('https://pages.onepgr.com/session/create-data-bank-table', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(nextPayload),
            });

            if (!nextResponse.ok) {
                throw new Error(`HTTP error! status: ${nextResponse.status}`);
            }

            const nextResult = await nextResponse.json();
            console.log(nextResult);
            // Handle the response from the second request, e.g., display a message
            displayMessage('Data bank table created successfully.', 'success');

        } catch (error) {
            console.error('Error:', error);
            // Handle error, e.g., showing error message
        } finally {

            setIsOverlayLoading(false);
            // Optionally hide the mapping interface
        }
    };

    const openOverlayWithFileInfo = async (file) => {
        console.log(file)
        setCurrentFile(file);
        setOverlayInfoOpen(true);

        // Fetch file information from the API
        try {
            const response = await axios.get(`https://pages.onepgr.com/session/get-data-bank-file-info?user_id=${file.user_id}&token=${file.token}`);
            const fileInfo = response.data.data.file_info;
            console.log(response)
            console.log(fileInfo)
            setFileDetails({
                ...fileDetails,
                [file._id]: {
                    comments: fileInfo.comments || '',
                    companyInfo: fileInfo.company_info || '',
                    tags: fileInfo.tags || []
                }
            });

        } catch (error) {
            console.error('Error fetching file information:', error);
            // Handle errors
        }
    };


    const openOverlayWithEditFileDetails = (file) => {
        setCurrentFile(file);

        // Set this to true to open the overlay in edit mode directly
        setIsEditMode(true);

        // Ensure file details for the current file are initialized
        if (!fileDetails[file._id]) {
            setFileDetails({
                ...fileDetails,
                [file._id]: { comments: '', companyInfo: '', tags: [] }
            });
        }
        setOverlayInfoOpen(true);
    };


    const handleInviteClick = (file) => {
        setOverlayInfoOpen(false);
        // Construct the URL with current domain, path, and query parameters
        const currentDomain = window.location.origin;
        const userId = file.user_id;
        const token = file.token;
        const invitePath = `/view?user_id=${userId}&token=${token}`;
        const constructedUrl = `${currentDomain}${invitePath}`;

        // Set the constructed URL to the state
        setInviteUrl(constructedUrl);

        // Show the invite overlay
        setShowInviteOverlay(true);
    };

    const handleCopyClick = () => {
        const constructedUrl = inviteUrl;

        const textarea = document.getElementById('inviteTextarea');
        if (textarea) {
            textarea.value = constructedUrl;
            textarea.select();
            document.execCommand('copy');

            // Set copyIcon to true to display the tick icon
            setCopyIcon(true);

            // Revert to the copy icon after 2 seconds
            setTimeout(() => {
                setCopyIcon(false);
            }, 2000);
        }
    };

    const closeInviteOverlay = () => {
        setShowInviteOverlay(false);
    };
    // Function to close overlay and clear current file info


    const updateFileDetails = (fileId, detailType, value) => {
        setFileDetails(prev => ({
            ...prev,
            [fileId]: {
                ...prev[fileId],
                [detailType]: value,
            },
        }));
    };

    const removeTag = (fileId, tagIndex) => {
        const updatedTags = [...fileDetails[fileId].tags];
        updatedTags.splice(tagIndex, 1); // Remove the tag at tagIndex
        updateFileDetails(fileId, 'tags', updatedTags);
    };

    const handleCancel = () => {
        setShowMappingInterface(false); // This will hide the overlay
    };

    const addTag = (newTagValue) => {
        // Guard clause to check if currentFile is set and newTagValue is not empty
        if (!currentFile || !newTagValue.trim()) return;

        const fileId = currentFile._id; // ID of the current file
        const existingTags = fileDetails[fileId]?.tags || []; // Current tags or an empty array if none

        // Create a new array with the existing tags plus the new one
        const updatedTags = [...existingTags, newTagValue.trim()];

        // Update fileDetails state with the new array of tags
        setFileDetails(prevDetails => ({
            ...prevDetails,
            [fileId]: {
                ...prevDetails[fileId],
                tags: updatedTags
            }
        }));
    };
    const toggleEditMode = () => {
        setIsEditMode((prevMode) => !prevMode);
    };

    const closeOverlayInfo = () => {
        setOverlayInfoOpen(false);
        setIsEditMode(false); // Optionally reset edit mode to off when closing the overlay
    };

    const saveChanges = async () => {
        setIsOverlayLoading(true);
        setOverlayInfoOpen(false);
        const fileId = currentFile._id;
        updateFileDetails(currentFile._id, 'name', editableFileName);

        const lockerToken = selectedLockerToken

        const requestBody = {
            name: editableFileName,
            source_url: currentFile.source_url,
            type: "Check",
            file_info: {
                comments: fileDetails[fileId]?.comments || '',
                company_info: fileDetails[fileId]?.companyInfo || '',
                tags: fileDetails[fileId]?.tags.join(', ') || '' // Assuming tags are stored as an array
            },
            date: currentDateTime
        };

        if (password) {
            requestBody.password = password;
        }

        if (lockerToken) {
            requestBody.locker_id = lockerToken;
        }


        try {
            const response = await axios.put(`https://pages.onepgr.com/session/update-data-bank-source-file/${fileId}`, requestBody);

            console.log(response.data);

            const updatedFileDetails = { ...fileDetails, [fileId]: requestBody.file_info };
            setFileDetails(updatedFileDetails);
            setIsOverlayLoading(false);
            fetchFiles()
            setSuccessMessage("File info updated successfully!");
            setTimeout(() => setSuccessMessage(''), 3000);
        } catch (error) {
            setIsLoading(false)
            console.error("Error updating file info:", error);
            setErrorMessage("Failed to update file info.");
            setTimeout(() => setErrorMessage(''), 3000);
        }
    };

    const toggleSortName = () => {
        setSortName(prevSort => (prevSort === 'asc' ? 'desc' : 'asc'));
    };



    const handleSortByName = () => {
        if (sortDirection === 'asc') {
            // If it's ascending, reset to no sort (or set to 'desc' if toggling between asc and desc)
            setDisplayedFiles([...displayedFiles].sort((a, b) => b.name.localeCompare(a.name)));
            setSortDirection('desc'); // Now set to descending
        } else {
            // If not sorted or descending, sort ascending
            setDisplayedFiles([...displayedFiles].sort((a, b) => a.name.localeCompare(b.name)));
            setSortDirection('asc');
        }
    };

    const toggleSortLastUpdated = () => {
        let sortedFiles;
        if (sortLastUpdated === 'asc') {
            // Currently ascending, toggle to original
            sortedFiles = [...displayedFiles].sort((a, b) => new Date(b.date) - new Date(a.date));
            setSortLastUpdated('desc');
        } else {
            // Either descending or original, sort by ascending (oldest to newest)
            sortedFiles = [...displayedFiles].sort((a, b) => new Date(a.date) - new Date(b.date));
            setSortLastUpdated('asc');
        }
        setDisplayedFiles(sortedFiles);
    };

    const handleSortByType = () => {
        let sortedFiles;
        if (sortType === 'desc') {
            sortedFiles = [...displayedFiles].sort((a, b) => {
                // Assuming `extractFileNameAndExtension` returns an object with `extension`
                const { extension: extensionA } = extractFileNameAndExtension(a.name);
                const { extension: extensionB } = extractFileNameAndExtension(b.name);
                return extensionA.localeCompare(extensionB);
            });
            setSortType('asc');
        } else {
            sortedFiles = [...displayedFiles].sort((a, b) => {
                const { extension: extensionA } = extractFileNameAndExtension(a.name);
                const { extension: extensionB } = extractFileNameAndExtension(b.name);
                return extensionB.localeCompare(extensionA);
            });
            setSortType('desc');
        }
        setDisplayedFiles(sortedFiles);
    };


    const handleSortBySize = () => {
        let sortedFiles;
        if (sortSize === 'asc') {
            sortedFiles = [...displayedFiles].sort((a, b) => parseFloat(a.size) - parseFloat(b.size));
            setSortSize('desc');
        } else {


            sortedFiles = [...displayedFiles].sort((a, b) => parseFloat(b.size) - parseFloat(a.size));
            setSortSize('asc');
        }
        setDisplayedFiles(sortedFiles);
    };


    const getIconByExtension = (url) => {
        if (!url) return faLock;  // Default if no URL is provided
        const extension = url.split('.').pop().toLowerCase(); // Extract extension
        switch (extension) {
            case 'pdf':
                return faFilePdf;
            case 'csv':
                return faFileCsv;
            case 'pptx':
                return faFilePowerpoint;
            case 'xlsx':
                return faFileExcel;
            case 'png':
            case 'jpg':
            case 'jpeg':
            case 'gif':
                return faFileImage;
            case 'zip':
                return faFileArchive;
            default:
                return faFile; // Default icon for unsupported or unknown file types
        }
    };


    const handleSelectAll = (event) => {
        setSelectAll(event.target.checked);
        if (event.target.checked) {
            setSelectedFiles(displayedFiles.map(file => file._id));
        } else {
            setSelectedFiles([]);
        }
    };




    if (showMappingCheckbox) {
        return (
            <div className="overlay">
                <div className="mapping-checkbox-interface">
                    <h2>Mapping data</h2>
                    <hr />
                    <div className='input-group'>
                        <label htmlFor="tableName">Table Name:</label>
                        <input
                            type="text"
                            id="tableName"
                            placeholder="Enter table name"
                            value={tableName} // Assuming you have a state variable to hold this value
                            onChange={(e) => setTableName(e.target.value)} // Update state on change
                            required
                        />
                    </div>
                    <div className='checkbox'>
                        <input
                            type="checkbox"
                            checked={mappingChecked}
                            onChange={e => setMappingChecked(e.target.checked)}
                        />
                        <p> Use OnePgr Table format</p>
                    </div>
                    <br />
                    <div className='checkboxBtns'>
                        <button className='check-cancel-btn' onClick={() => {
                            // Proceed to column mapping interface
                            setShowMappingCheckbox(false); // Hide current interface
                            setSelectedFiles([])
                        }}>Cancel</button>
                        {mappingChecked ? (
                            <button onClick={() => {
                                setShowMappingInterface(true); // Proceed to column mapping interface
                                setShowMappingCheckbox(false); // Hide current interface
                            }}>Next</button>
                        ) : (
                            <button onClick={() => {
                                // Replace the following with your actual function to send data to RDS
                                sendSelectedFilesToRDS(tableName);
                                setShowMappingCheckbox(false); // Hide current interface
                            }}>Send to RDS</button>
                        )}
                    </div>
                </div>
            </div>
        );
    }


    if (showMappingInterface) {
        return (
            <div className="overlay">
                <div className="mapping-interface">
                    <div className='mappingHead'>
                        <h2>Mapping column fields</h2>
                        <div className="on-off-toggle">
                            <label htmlFor="toggle">Auto Mapping</label>
                            <input
                                type="checkbox"
                                id="toggle"
                                checked={autoMappingEnabled}
                                onChange={handleAutoMappingToggle}
                            />
                            <label htmlFor="toggle" className="toggle-button"></label>
                        </div>
                    </div>
                    <hr />

                    <table>
                        <thead>
                            <tr>
                                <th>Source Fields</th>
                                <th>Onepgr Fields</th>
                                <th>Example Data</th>
                            </tr>
                        </thead>
                        <tbody>
                            {columnMappings.map((mapping, index) => {
                                // Directly use onepgrField from the state, which is managed by handleMappingChange
                                const onepgrField = mapping.onepgrField || '';

                                return (
                                    <tr key={index}>
                                        <td>{mapping.sourceField}</td>
                                        <td>
                                            <select
                                                value={onepgrField}
                                                onChange={(e) => handleMappingChange(index, e.target.value)}
                                            >
                                                <option value="">Select Field</option>
                                                {columnNames.map((name, idx) => (
                                                    <option key={idx} value={name}>{name}</option>
                                                ))}
                                            </select>
                                        </td>
                                        <td>{mapping.exampleData}</td>
                                    </tr>
                                );
                            })}

                        </tbody>
                    </table>
                    <button className='cancelmap' onClick={handleCancel}>Cancel</button>
                   <button onClick={() => finalizeMappings(selectedFiles, columnMappings, tableName, targetDb)}>Submit</button>
                </div>
            </div>
        );
    }


    return (

        <div className="files-card">

            <div className="file-list-actions">


                {activeTab === 'MyFiles' && (
                    <div className="search-bar" style={{ display: 'flex' }}>
                        <div className="input-container">
                            <div className="icon-card searchIcon" onClick={toggleSearch}>
                                <FontAwesomeIcon icon={faSearch} />
                            </div>
                            <input
                                type="text"
                                id="searchInput"
                                placeholder="Search Files..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                                className='search-input'
                            />
                        </div>
                    </div>
                )}


                <div className="icon-container option-icon-container">
                    <div ref={dropdownRef}>
                        <OverlayTrigger
                            key="top-send"
                            placement="top"
                            overlay={<Tooltip id="tooltip-top-send">Must select file(s)</Tooltip>}
                        >
                            <div
                                className="icon-card options"
                                onClick={handleIconClick}
                                style={{
                                    opacity: selectedFiles.length > 0 ? 1 : 0.5,
                                    cursor: selectedFiles.length > 0 ? 'pointer' : 'default'
                                }}

                            >
                                {showSendOptions && (
                                    <div className="send-options">
                                        <ul>
                                            {selectedFiles.length === 1 ? (
                                               <li
                                               className={`sendToRDS ${selectedFileExtension === 'csv' || selectedFileExtension === 'xls' || selectedFileExtension === 'xlsx' ? '' : 'disabled'}`}
                                               onClick={() => {
                                                   if (selectedFileExtension === 'csv' || selectedFileExtension === 'xls' || selectedFileExtension === 'xlsx') {
                                                       handleSendToRDSClick(displayedFiles.find(file => file._id === selectedFiles[0]), 'rds');
                                                   }
                                               }}
                                           >
                                               <FontAwesomeIcon icon={faTable} className='options-icon' /> <span>Create Table in RDS</span>
                                           </li>
                                            ) : (
                                                <li
                                                    className={`sendToRDS ${selectedFiles.length > 1 ? '' : 'disabled'}`}
                                                    onClick={sendMultipleSelectedFilesToRDS}
                                                >
                                                    <FontAwesomeIcon icon={faTable} className='options-icon' /> <span>Create Tables in RDS</span>
                                                </li>
                                            )}

                                            {selectedFiles.length === 1 ? (
                                               <li
                                               className={`sendToSnowflake ${selectedFileExtension === 'csv' || selectedFileExtension === 'xls' || selectedFileExtension === 'xlsx' ? '' : 'disabled'}`}
                                               onClick={() => {
                                                   if (selectedFileExtension === 'csv' || selectedFileExtension === 'xls' || selectedFileExtension === 'xlsx') {
                                                       handleSendToRDSClick(displayedFiles.find(file => file._id === selectedFiles[0]), 'snowflake');
                                                   }
                                               }}
                                           >
                                               <FontAwesomeIcon icon={faSnowflake} className='options-icon' /> <span>Create Table in Snowflake</span>
                                           </li>
                                            ) : (
                                                <li
                                                    className={`sendToSnowflake ${selectedFiles.length > 1 ? '' : 'disabled'}`}
                                                    onClick={() => sendMultipleSelectedFilesToRDS('snowflake')}
                                                >
                                                    <FontAwesomeIcon icon={faSnowflake} className='options-icon' /> <span>Create Tables in Snowflake</span>
                                                </li>
                                            )}
                                            <li className="sendToDashboard" onClick={sendToQuickSight}>
                                                <FontAwesomeIcon icon={faChartLine} className='options-icon' /><span>Send to QuickSight Dashboard</span>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                                Options
                            </div>
                        </OverlayTrigger>
                    </div>

                    {activeTab === 'MyFiles' && (
                        <OverlayTrigger
                            key="top-refresh"
                            placement="top"
                            overlay={<Tooltip id={`tooltip-top-refresh`}>Refresh File List</Tooltip>}
                        >
                            <div className="icon-card" onClick={fetchFiles}>
                                <FontAwesomeIcon icon={faSyncAlt} />
                            </div>
                        </OverlayTrigger>
                    )}
                </div>
            </div>

            <div id="fileList">
                <div className="file-header">
                    <span onClick={handleSortByName}>
                        <input
                            id='selectall-input-header'
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                        />
                        Name <FontAwesomeIcon icon={sortIcon} className='sort-icon' />
                    </span>
                    <span onClick={handleSortByType} className='file-type'>
                        File Type  {sortType === 'desc' ? <FontAwesomeIcon icon={faArrowUp} className='sort-icon' /> : <FontAwesomeIcon icon={faArrowDown} className='sort-icon' />}
                    </span>
                    <span onClick={handleSortBySize} className='file-size'>
                        Size {sortSize === 'asc' ? <FontAwesomeIcon icon={faArrowUp} className='sort-icon' /> : <FontAwesomeIcon icon={faArrowDown} className='sort-icon' />}
                    </span>
                    <span onClick={toggleSortLastUpdated}>
                        Last Updated {sortLastUpdated === 'asc' ? <FontAwesomeIcon icon={faArrowUp} className='sort-icon' /> : <FontAwesomeIcon icon={faArrowDown} className='sort-icon' />}
                    </span>
                    <span className='actions-header'>Actions</span>
                </div>
                {isLoading ? (
                    <div className="spinner"></div>

                ) : (
                    displayedFiles && displayedFiles.length > 0 ? (
                        displayedFiles.map(file => {
                            let urlParts, baseName, extension;

                            if (file.source_url) {
                                urlParts = new URL(file.source_url);
                                const typeExtract = urlParts.pathname.split('/').pop();
                                ({ baseName, extension } = extractFileNameAndExtension(typeExtract));
                            } else {
                                // Handle the case when source_url is missing
                                urlParts = null;
                                baseName = '';
                                extension = '';
                            }
                            const fileName = file.name;
                            const indexOfDot = fileName.lastIndexOf('.');
                            const NewFileName = indexOfDot !== -1 ? fileName.substring(0, indexOfDot) : fileName;




                            // Placeholder since size isn't in the provided data structure
                            const isFileSizeFormatted = typeof file.size === 'string' && file.size.includes(' ');

                            // Format the file size only if it's not already formatted
                            const readableFileSize = isFileSizeFormatted ? file.size : formatFileSize(file.size);
                            const formatedDate = formatDate(file.date)

                            return (
                                <div key={file._id} className={`file-item ${selectedFiles.includes(file._id) ? 'selected' : ''}`}>
                                    <div className='inputName' title={NewFileName} >
                                        <input
                                            id='selectall-input'
                                            type="checkbox"
                                            checked={selectAll || selectedFiles.includes(file._id)}
                                            onChange={() => selectFile(file._id)}
                                        />
                                        <div className="file-name">
                                            <FontAwesomeIcon icon={getIconByExtension(file.source_url)} className="icon-folder-lock" />
                                            <span className='fileName' onClick={() => viewFile(file.source_url)} title={NewFileName}> {NewFileName}</span>
                                        </div>
                                    </div>

                                    <span className='spanlabel file-type'>{extension}</span>
                                    <span className='spanlabel file-size'>{readableFileSize}</span>
                                    <span className='spanlabel'>{formatedDate}</span>

                                    <div className="file-actions-icon">
                                        <div className='actions-icon-full'>
                                            <OverlayTrigger
                                                key={`top-info-${file._id}`}
                                                placement="top"
                                                overlay={<Tooltip id={`tooltip-top-info`}>File Information</Tooltip>}
                                            >
                                                <Button variant="light" onClick={() => openOverlayWithFileInfo(file)}>
                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                </Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                key={`top-view-${file._id}`}
                                                placement="top"
                                                overlay={<Tooltip id={`tooltip-view`}>View File</Tooltip>}
                                            >
                                                <Button variant="light" onClick={(event) => {
                                                    event.stopPropagation();
                                                    viewFile(file.source_url);
                                                }}>
                                                    <FontAwesomeIcon icon={faEye} />
                                                </Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                key={`top-edit-${file._id}`}
                                                placement="top"
                                                overlay={<Tooltip id={`tooltip-top-info`}>Edit File information</Tooltip>}
                                            >
                                                <Button variant="light" onClick={() => openOverlayWithEditFileDetails(file)}>
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </Button>
                                            </OverlayTrigger>



                                            <OverlayTrigger
                                                key={`top-create-table-${file._id}`}
                                                placement="top"
                                                overlay={<Tooltip id={`tooltip-create-table`}>Create Table</Tooltip>}
                                            >
                                                <Button variant="light" onClick={(event) => {
                                                    event.stopPropagation();
                                                    if (!selectedFiles.includes(file._id)) {
                                                        selectFile(file._id);
                                                    }
                                                    handleSendToRDSClick(file);
                                                }}>
                                                    <FontAwesomeIcon icon={faTable} />
                                                </Button>
                                            </OverlayTrigger>

                                            <OverlayTrigger
                                                key={`top-invite-${file._id}`}
                                                placement="top"
                                                overlay={<Tooltip id={`tooltip-invite`}>Share</Tooltip>}
                                            >
                                                <Button variant="light" onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleInviteClick(file);
                                                }}>
                                                    <FontAwesomeIcon icon={faUserPlus} />
                                                </Button>
                                            </OverlayTrigger>


                                            <OverlayTrigger
                                                key={`top-delete-${file._id}`}
                                                placement="top"
                                                overlay={<Tooltip id={`tooltip-top-delete`}>Delete</Tooltip>}
                                            >
                                                <Button variant="light" onClick={(event) => {
                                                    event.stopPropagation();
                                                    deleteFile(file.source_url, file._id); // Ensure your deleteFile can handle URLs
                                                }}>
                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                </Button>
                                            </OverlayTrigger>
                                        </div>
                                        <div className="actions-icon-ellipsis">
                                            <OverlayTrigger
                                                key={`top-more-${file._id}`}
                                                placement="top"
                                                overlay={<Tooltip id={`tooltip-top-more`}>More</Tooltip>}
                                            >
                                                <Button variant="light" onClick={(event) => toggleDropdown(event, file._id)}>
                                                    <FontAwesomeIcon icon={faEllipsisV} />
                                                </Button>
                                            </OverlayTrigger>

                                            {showDropdown === file._id && (
                                                <div className="actions-dropdown">
                                                    <div className="actions-dropdown-icon-button">
                                                        <Button variant="light"
                                                            onClick={() => openOverlayWithFileInfo(file)}

                                                        >
                                                            <FontAwesomeIcon icon={faInfoCircle} className='dropdown-menu-icon' />
                                                            <p>   View File Details</p>
                                                        </Button>
                                                    </div>
                                                    <div className="actions-dropdown-icon-button">
                                                        <Button variant="light" onClick={(event) => {
                                                            event.stopPropagation();
                                                            viewFile(file.source_url);
                                                        }}>
                                                            <FontAwesomeIcon icon={faEye} className='dropdown-menu-icon' />
                                                            <p>   View File</p>
                                                        </Button>
                                                    </div>
                                                    <div className="actions-dropdown-icon-button">
                                                        <Button variant="light"
                                                            onClick={() => openOverlayWithEditFileDetails(file)}
                                                        >
                                                            <FontAwesomeIcon icon={faEdit} className='dropdown-menu-icon' />
                                                            <p>Edit File</p>
                                                        </Button>
                                                    </div>
                                                    <div className="actions-dropdown-icon-button">
                                                        <Button variant="light" onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleInviteClick();
                                                        }}>
                                                            <FontAwesomeIcon icon={faUserPlus} className='dropdown-menu-icon' />
                                                            <p>Share</p>
                                                        </Button>

                                                    </div>
                                                    <div className="actions-dropdown-icon-button">
                                                        <Button variant="light" onClick={(event) => {
                                                            event.stopPropagation();
                                                            deleteFile()
                                                        }}>
                                                            <FontAwesomeIcon icon={faTrashAlt} className='dropdown-menu-icon' />
                                                            <p>Delete</p>
                                                        </Button>
                                                    </div>

                                                </div>
                                            )}
                                        </div>
                                        {overlayInfoOpen && currentFile && (
                                            <div className="fileInfo-overlay">
                                                <div className="fileInfo-overlay-content" >
                                                    {/* <h3>{currentFile.name || 'File Info'}</h3> */}
                                                    <h3>{isEditMode ? "Edit File Details:" : "View File Details:"}</h3>
                                                    <div className='editname'>
                                                        {isEditMode ? (
                                                            <input
                                                                type="text"
                                                                value={editableFileName}
                                                                onChange={(e) => setEditableFileName(e.target.value)}
                                                                className="filename-editable-input"
                                                            />
                                                        ) : (
                                                            <p>{editableFileName || currentFile.name || 'File Info'}</p>
                                                        )}
                                                    </div>
                                                    <div className='locker-lable-content'>
                                                        <label className='label-locker-name'>Locker:</label>
                                                        {isEditMode ? (
                                                            <select
                                                                value={selectedLocker}
                                                                onChange={(e) => setSelectedLocker(e.target.value)}
                                                                className="locker-dropdown"
                                                            >
                                                                <option value=" "> {lockerDetails.find((locker) =>
                                                                    locker.token === (currentFile?.locker_id)

                                                                )?.name || '-- Select Locker --'}
                                                                </option>
                                                                {lockerDetails?.map((locker) => (
                                                                    <option key={locker.token} value={locker.name}>
                                                                        {locker.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        ) : (
                                                            <span className='locker-name-dropdown'>
                                                                {lockerDetails.find((locker) =>
                                                                    locker.token === (currentFile?.locker_id)

                                                                )?.name}

                                                            </span>
                                                        )}
                                                    </div>
                                                    {isEditMode && (
                                                        <div className='file-password-label-content'>
                                                            <label className='file-label-password'>Password:</label>
                                                            <input
                                                                type="text"
                                                                value={password !== null ? password : currentFile?.password || ''}
                                                                onChange={(e) => setPassword(e.target.value)}
                                                                className="file-password-input"
                                                                placeholder='Add Password'
                                                            />
                                                        </div>
                                                    )}

                                                    <hr />
                                                    <div className='form-content'>
                                                        <div className='editmode-icon-share-edit'>
                                                            <OverlayTrigger
                                                                key={`top-invite-${file._id}`}
                                                                placement="top"
                                                                overlay={<Tooltip id={`tooltip-invite`}>Share</Tooltip>}
                                                            >
                                                                <Button variant="light" onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    handleInviteClick(file);
                                                                }}>
                                                                    <FontAwesomeIcon icon={faUserPlus} />
                                                                </Button>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                key={`top-edit-2-${currentFile?._id}`}
                                                                placement="top"
                                                                overlay={<Tooltip id={`tooltip-top-edit`}>Edit</Tooltip>}
                                                            >
                                                                <Button variant="light" className='editinfobtn' onClick={toggleEditMode}>
                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                </Button>
                                                            </OverlayTrigger>
                                                        </div>
                                                        <div className='info-form-group '>
                                                            <label>Comments:</label>
                                                            <textarea
                                                                value={fileDetails[currentFile._id]?.comments || ''}
                                                                onChange={(e) => updateFileDetails(currentFile._id, 'comments', e.target.value)}
                                                                readOnly={!isEditMode}
                                                                className={isEditMode ? 'textarea-editable' : 'textarea-readonly'}
                                                            />
                                                        </div>
                                                        <div className='info-form-group '>
                                                            <label>Company Info:</label>
                                                            <textarea
                                                                value={fileDetails[currentFile._id]?.companyInfo || ''}
                                                                onChange={(e) => updateFileDetails(currentFile._id, 'companyInfo', e.target.value)}
                                                                readOnly={!isEditMode}
                                                                className={isEditMode ? 'textarea-editable' : 'textarea-readonly'}
                                                            />
                                                        </div>
                                                        <div className="info-form-group">
                                                            <label>Tags:</label>
                                                            {isEditMode && (
                                                                <input
                                                                    className="input-tag"
                                                                    type="text"
                                                                    value={newTag} // Assumes you have a state [newTag, setNewTag] for handling the input value
                                                                    onChange={(e) => setNewTag(e.target.value)}
                                                                    readOnly={!isEditMode}
                                                                    onKeyPress={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            addTag(newTag); // Add the tag
                                                                            setNewTag(''); // Clear the input field
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                />
                                                            )}

                                                            {/* Display tags dynamically based on the current file */}
                                                            <div className='tag-info'>
                                                                {fileDetails[currentFile._id]?.tags.map((tag, index) => (
                                                                    <span key={index} className="tag-tile">
                                                                        {tag}
                                                                        {isEditMode && (
                                                                            <span className="tag-remove-btn" onClick={() => removeTag(currentFile._id, index)}>×</span>
                                                                        )}
                                                                    </span>
                                                                ))}
                                                            </div>

                                                        </div>
                                                        {isEditMode && (
                                                            <div className='btn-fileinfo'>

                                                                <Button className='doneFileinfo' variant="primary" onClick={saveChanges}>Done</Button>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <button onClick={closeOverlayInfo} className='x-btn' style={{ position: 'absolute', top: '20px', right: '20px', fontSize: '20px' }}>X</button>
                                                </div>
                                            </div>
                                        )}

                                        {showInviteOverlay && (
                                            <InviteOverlay
                                                showInviteOverlay={showInviteOverlay}
                                                closeInviteOverlay={closeInviteOverlay}
                                                inviteUrl={inviteUrl}
                                                handleCopyClick={handleCopyClick}
                                                copyIcon={copyIcon}
                                            />
                                        )}


                                    </div>


                                </div>
                            );
                        })
                    ) : (
                        <div className="no-files-message">No files found.</div>
                    )
                )}

            </div>
            <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={totalItems}
                paginate={setCurrentPage}
                currentPage={currentPage}
                setItemsPerPage={setItemsPerPage} // Pass the state setter as a prop
            />



            <div id="notification-container"></div>


            {isOverlayLoading && (
                <div className="overlayLoading">
                    <div className="overlayspinner"></div>
                </div>
            )}
            {successMessage && (
                <div className="success-message">
                    {successMessage}
                </div>
            )}
            {errorMessage && (
                <div className="error-message">
                    {errorMessage}
                </div>
            )}
        </div>

    );
}

export default FileRepository;
